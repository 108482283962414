import { WebPlayer, StreamProtection, StreamProtocol, } from '@zattoo/playback-sdk';
import { BitmovinAdapterWithIma } from '@zattoo/playback-sdk/adapter/bitmovin';
export const init = () => {
    // nothing to initialize for apple
    // but keep the function for compatibility with other platforms
    return Promise.resolve();
};
export const createPlayer = (options) => {
    const capability = {
        streamProtocol: StreamProtocol.DASH,
        streamProtection: StreamProtection.WIDEVINE,
    };
    return new WebPlayer(options, new BitmovinAdapterWithIma(options), capability);
};
export { PlayerView, } from './player-view';
