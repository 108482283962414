import { WithImaSdk } from './sdk';
export const WithIma = (Base) => {
    return class AdapterWithImaSdk extends WithImaSdk(Base) {
        setVolume(volume) {
            if (this.isAdPlaying()) {
                return this.imaSetVolume(volume);
            }
            else {
                return super.setVolume(volume);
            }
        }
        play() {
            if (this.isAdPlaying()) {
                return this.imaPlay();
            }
            else {
                return super.play();
            }
        }
        pause() {
            if (this.isAdPlaying()) {
                return this.imaPause();
            }
            else {
                return super.pause();
            }
        }
    };
};
