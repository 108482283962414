import { getVOD } from '@zattoo/zapi';
import { MediaType } from './enums';
import { SubtitlesCodec, getStreamingProtocol, } from '../capability';
import { EventKey, PlaybackState, } from '../player/enums';
import { generatePsid } from '../player/utils';
export const PlayerWithVod = (Base) => {
    return class extends Base {
        watchResponse = null;
        get isVod() {
            return this.mediaType === MediaType.VOD;
        }
        // eslint-disable-next-line max-params
        async playVod(token, teasableId, teasableType, playOptions) {
            try {
                const psid = generatePsid();
                const capability = this.capability;
                await this.stop();
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    stream_type: streamType,
                    teasable_id: teasableId,
                    teasable_type: teasableType,
                    term_token: token,
                    https_watch_urls: true,
                };
                if (capability?.subtitlesCodec) {
                    param.vtt = SubtitlesCodec.VTT === capability.subtitlesCodec;
                    param.ttml = SubtitlesCodec.TTML === capability.subtitlesCodec;
                }
                this.triggerEvent(EventKey.WATCH_REQUESTED, {
                    psid,
                });
                this.watchResponse = await getVOD(param);
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent(EventKey.WATCH_RECEIVED, {
                    data: this.watchResponse,
                    psid,
                });
                const adapterRequest = {
                    type: MediaType.VOD,
                    parameters: {
                        token,
                        teasableId,
                        teasableType,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                };
                this.psid = psid;
                const adapterMedia = await this.adapter.load(adapterRequest);
                return this.dispatchMedia(adapterRequest, adapterMedia);
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isVod || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: (this.currentState === PlaybackState.PLAYING ||
                    this.currentState === PlaybackState.BUFFERING),
                canSeekBackward: true,
                canSeekForward: true,
                seekableRange: {
                    start: 0,
                    end: this.duration ?? 0,
                },
                ...newState,
            });
        }
    };
};
