import React from 'react';
import { PlayerManager } from '../player/player-manager';
import { ButtonsList } from '../layout/buttons-list';
import { getChannels, getChannelGroups, needsDrm, } from '../live/utils';
import { useChannels } from '../live/channels-context';
import { useOsd } from '../player/osd-context';
export const Live = (props) => {
    const channels = useChannels().channels;
    const groups = useChannels().groups;
    const sandboxChannels = channels
        ?.filter((channel) => (getChannels().includes(channel.cid)));
    const { setOsd, osd, } = useOsd();
    const channelGroups = getChannelGroups()
        .map((selected) => ({
        name: groups.find((group) => group.id === selected)?.name ?? '',
        channels: channels?.filter((channel) => channel.group_ids?.includes(selected)),
    }));
    const playLive = (cid) => {
        const playerManager = PlayerManager.getInstance();
        const player = playerManager.getPlayer();
        const channel = channels?.find((c) => c.cid === cid);
        const drmRequired = channel ? needsDrm(channel) : false;
        setOsd({
            channelId: cid,
            programStart: 0,
            programEnd: 0,
        });
        player?.playLive(cid, {
            drmRequired,
            pin: osd?.pin,
        }).then((data) => console.log('playLive', data))
            .catch((error) => console.error('playLive', error));
        props.onChannelSelected(cid);
    };
    if (!channels || !sandboxChannels) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonsList, { onItemSelected: playLive, items: sandboxChannels.map((channel) => ({
                title: channel.title,
                id: channel.cid,
                metaInfo: [`drm: ${needsDrm(channel) ? 'yes' : 'no'}`],
            })), listLabel: "Live" }),
        channelGroups.map((group) => (React.createElement(ButtonsList, { key: group.name, onItemSelected: playLive, items: group.channels.map((channel) => ({
                title: channel.title,
                id: channel.cid,
                metaInfo: [`drm: ${needsDrm(channel) ? 'yes' : 'no'}`],
            })), listLabel: group.name })))));
};
