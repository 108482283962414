export var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["Network"] = 10000] = "Network";
    ErrorCode[ErrorCode["NotImplemented"] = 10001] = "NotImplemented";
    ErrorCode[ErrorCode["Initialization"] = 10002] = "Initialization";
    ErrorCode[ErrorCode["AudioTrack"] = 10003] = "AudioTrack";
    ErrorCode[ErrorCode["SubtitlesTrack"] = 10004] = "SubtitlesTrack";
    ErrorCode[ErrorCode["Unload"] = 10005] = "Unload";
    ErrorCode[ErrorCode["Source"] = 10006] = "Source";
    ErrorCode[ErrorCode["ForbiddenAction"] = 10007] = "ForbiddenAction";
    ErrorCode[ErrorCode["PinRequired"] = 10008] = "PinRequired";
    ErrorCode[ErrorCode["Ad"] = 10009] = "Ad";
    ErrorCode[ErrorCode["Unknown"] = 10999] = "Unknown";
})(ErrorCode || (ErrorCode = {}));
