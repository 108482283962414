import { EventKey, } from '../enums';
export const WithEvent = (Base) => {
    return class PlayerWithEvent extends Base {
        listeners = new Map();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        constructor(...args) {
            super(...args);
            this.attachAdapterCallbacks();
        }
        triggerFullEvent = (event) => {
            const handler = this.listeners.get(event.type);
            handler?.forEach((listener) => listener(event));
        };
        triggerEvent = (eventKey, payload) => {
            const event = {
                type: eventKey,
                psid: this.psid,
                ...payload,
            };
            const ignoredEventKeysForLogging = [
                EventKey.CURRENT_POSITION_CHANGED,
                EventKey.PLAYER_STATE_CHANGED,
            ];
            if (this._logger && !ignoredEventKeysForLogging.includes(eventKey)) {
                this._logger?.info('Dispatching event', event);
            }
            this.triggerFullEvent(event);
        };
        on = (eventKey, listener) => {
            if (!this.listeners.has(eventKey)) {
                this.listeners.set(eventKey, []);
            }
            this.listeners.get(eventKey)?.push(listener);
        };
        off = (eventKey, listener) => {
            const eventListeners = this.listeners.get(eventKey);
            if (!eventListeners) {
                return;
            }
            this.listeners.set(eventKey, eventListeners.filter((l) => l !== listener));
        };
        attachAdapterCallbacks() {
            this.adapter.onPlaybackDurationChanged = (duration) => {
                this._duration = duration;
            };
            this.adapter.onPlayerReady = () => {
                this.triggerEvent(EventKey.PLAYER_READY, {});
            };
            this.adapter.onSeekableRangeChanged = (seekableRange) => {
                if (this._seekableRange?.start !== seekableRange?.start ||
                    this._seekableRange?.end !== seekableRange?.end) {
                    this.setPlayerState({
                        seekableRange,
                    });
                }
            };
            this.adapter.onPositionChanged = (position) => {
                if (this.seekPosition != null) {
                    return;
                }
                this._currentPosition = position;
                this.triggerEvent(EventKey.CURRENT_POSITION_CHANGED, {
                    position,
                });
            };
            this.adapter.onAvailableAudioTracksChanged = (audioTracks) => {
                this.availableAudioTracks = audioTracks;
                this.triggerEvent(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, {
                    tracks: audioTracks,
                });
            };
            this.adapter.onAvailableSubtitlesTracksChanged = (subtitleTracks) => {
                this.availableSubtitlesTracks = subtitleTracks;
                this.triggerEvent(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, {
                    tracks: subtitleTracks,
                });
            };
            this.adapter.onSeeked = (position) => {
                const seekedTime = Math.round(position - (this._currentPosition ?? 0));
                this.seekPosition = null;
                this._currentPosition = position;
                this.triggerEvent(EventKey.SEEKED, {
                    position,
                    seekedTime,
                });
            };
            this.adapter.onSelectedSubtitlesTrack = (targetTrack) => {
                this.selectedSubtitlesTrack = targetTrack;
                this.triggerEvent(EventKey.SELECTED_SUBTITLES_TRACK_CHANGED, {
                    targetTrack,
                });
            };
            this.adapter.onSelectedAudioTrackChanged = (targetTrack) => {
                this.selectedAudioTrack = targetTrack;
                this.triggerEvent(EventKey.SELECTED_AUDIO_TRACK_CHANGED, {
                    targetTrack,
                });
            };
            this.adapter.onPlaybackStateChanged = (state) => {
                this._playbackState = state;
                this.triggerEvent(EventKey.PLAYBACK_STATE_CHANGED, {
                    state,
                });
                this.setPlayerState({});
            };
            this.adapter.onError = (error) => {
                this.handleError(error);
            };
        }
    };
};
