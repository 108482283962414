import { MediaType } from '../../stream/enums';
export const WithImaActions = (Base) => {
    return class ImaWithActions extends Base {
        _isAdPlaying = false;
        isAdPlaying() {
            return this._isAdPlaying;
        }
        mediaType = null;
        load(request) {
            this.mediaType = request.type;
            return super.load(request);
        }
        onAdapterContentPauseRequested = () => {
            if (this.mediaType === MediaType.LIVE) {
                this.mute();
            }
            else {
                this.pause();
            }
            this._isAdPlaying = true;
        };
        onAdapterContentResumeRequested = () => {
            this._isAdPlaying = false;
            if (this.mediaType === MediaType.LIVE) {
                this.unmute();
            }
            else {
                this.play();
            }
        };
    };
};
