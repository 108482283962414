import React, { useEffect, useState, } from 'react';
import { PlayerManager } from '../player/player-manager';
import { getRecordings, formatTitle, } from './utils';
import { ButtonsList } from '../layout/buttons-list';
import { useSessionContext } from '../authentication/context';
import { useChannels } from '../live/channels-context';
import { needsDrm } from '../live/utils';
import { useOsd } from '../player/osd-context';
export const Recording = () => {
    const session = useSessionContext();
    const channels = useChannels().channels;
    const { setOsd, osd, } = useOsd();
    const [recordings, setRecordings] = useState(null);
    useEffect(() => {
        if (!session) {
            return;
        }
        if (!session.session?.content_pages?.recordings) {
            return;
        }
        getRecordings().then((playlistReponse) => setRecordings(playlistReponse?.recordings || null));
    }, [session]);
    const channelNeedsDrm = (cid) => {
        if (!channels) {
            return false;
        }
        const channel = channels.find((c) => c.cid === cid);
        if (!channel) {
            return false;
        }
        return needsDrm(channel);
    };
    const playRecording = (recordingId) => {
        const playerManager = PlayerManager.getInstance();
        const player = playerManager.getPlayer();
        if (!player) {
            return;
        }
        const recording = recordings?.find((r) => String(r.id) === recordingId);
        const cid = recording?.cid;
        if (!cid) {
            return;
        }
        if (recording) {
            setOsd({
                programStart: new Date(recording.start).getTime(),
                programEnd: new Date(recording.end).getTime(),
                recordingId: parseInt(recordingId, 10),
                channelId: cid,
            });
        }
        player.playRecording(Number(recordingId), {
            preferredAudioLanguage: 'de',
            preferredSubtitlesLanguage: 'de',
            drmRequired: channelNeedsDrm(cid),
            pin: osd?.pin,
        }).then((data) => console.log('playRecording', data))
            .catch((error) => console.error('playRecording', error));
    };
    if (!session || !recordings) {
        return null;
    }
    const recordingsEntries = recordings
        ?.filter((recording) => {
        return new Date(recording.end) <= new Date();
    })
        .map((recording) => {
        return {
            title: formatTitle(recording),
            id: String(recording.id),
            metaInfo: [
                `id: ${recording.id}`,
                `cid: ${recording.cid}`,
                `drm: ${channelNeedsDrm(recording.cid) ? 'yes' : 'no'}`,
            ],
        };
    }) || [];
    return (React.createElement(ButtonsList, { onItemSelected: playRecording, items: recordingsEntries, listLabel: "Recordings" }));
};
