import { isZAPIError, ErrorCode as ZAPIErrorCodes, } from '@zattoo/zapi/lib/helpers/error';
import { EventKey, PlaybackState, } from '../enums';
import { isPlaybackError } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
export const WithError = (Base) => {
    return class PlayerWithError extends Base {
        handlePinErrror(error) {
            const isZapiPinError = isZAPIError(error) && (error.code === ZAPIErrorCodes.PIN_MISSING ||
                error.code === ZAPIErrorCodes.PIN_INVALID ||
                error.code === ZAPIErrorCodes.PIN_SETUP_REQUIRED ||
                error.code === ZAPIErrorCodes.PIN_LOCKED);
            const isPlaybackPinError = isPlaybackError(error) && (error.code === ErrorCode.PinRequired);
            if (isZapiPinError || isPlaybackPinError) {
                this.pause();
                const pinStop = (event) => {
                    if (event.state === PlaybackState.PAUSED) {
                        this._playbackState = PlaybackState.STOPPED;
                        this.triggerEvent(EventKey.PLAYBACK_STATE_CHANGED, {
                            state: PlaybackState.STOPPED,
                        });
                    }
                    this.off(EventKey.PLAYBACK_STATE_CHANGED, pinStop);
                };
                this.on(EventKey.PLAYBACK_STATE_CHANGED, pinStop);
            }
        }
        handleError(error) {
            this.handlePinErrror(error);
            return super.handleError(error);
        }
    };
};
