import { PlaybackError } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
export const WithControl = (Base) => {
    return class PlayerWithControl extends Base {
        play() {
            this.adapter.play();
        }
        pause() {
            if (!this._canPause) {
                this.handleError(new PlaybackError(ErrorCode.ForbiddenAction, 'Pause is not allowed'));
                return;
            }
            this.adapter.pause();
        }
        stop() {
            return Promise.resolve();
        }
        validateSeekForward() {
            if (!this._canSeekForward) {
                this.handleError(new PlaybackError(ErrorCode.ForbiddenAction, 'Seek forward is not allowed'));
            }
            return this._canSeekForward;
        }
        validateSeekBackward() {
            if (!this._canSeekBackward) {
                this.handleError(new PlaybackError(ErrorCode.ForbiddenAction, 'Seek backward is not allowed'));
            }
            return this._canSeekBackward;
        }
        seek(position) {
            const isForward = position > (this._currentPosition ?? 0);
            const isBackward = position < (this._currentPosition ?? 0);
            if (isForward && !this.validateSeekForward()) {
                return;
            }
            if (isBackward && !this.validateSeekBackward()) {
                return;
            }
            this.adapter.seek(position);
        }
        seekBackward(steps) {
            if (!this.validateSeekBackward()) {
                return;
            }
            if (this._currentPosition == null) {
                return;
            }
            const offset = steps * this._playerOptions.stepBackwardDuration;
            if (this.seekPosition == null) {
                this.seekPosition = this._currentPosition - offset;
            }
            else {
                this.seekPosition -= offset;
            }
            this.seek(this.seekPosition);
        }
        seekForward(steps) {
            if (!this.validateSeekForward()) {
                return;
            }
            if (this._currentPosition == null) {
                return;
            }
            const offset = steps * this._playerOptions.stepForwardDuration;
            if (this.seekPosition == null) {
                this.seekPosition = this._currentPosition + offset;
            }
            else {
                this.seekPosition += offset;
            }
            this.seek(this.seekPosition);
        }
        setVolume(value) {
            this._volume = value;
            this.adapter.setVolume(value);
        }
        setRate(rate) {
            rate;
            this._logger?.info('not implemented: setRate');
        }
    };
};
