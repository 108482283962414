import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    body: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        gap: 16,
        backgroundColor: '#222222',
    },
    text: {
        color: 'white',
        textAlign: 'center',
        fontSize: 24,
    },
});
