import { injectScript } from '../utils/utils';
import * as config from '../utils/ima/config';
import { WithImaCallbacksDispatcher } from './callbacks-dispatcher';
import { PLAYER_VIDEO_ID } from '../constants';
import { IMA_CONTAINER_ID } from './constants';
let ima = null;
let adsLoader = null;
let adsManager = null;
let adDisplayContainer = null;
let adsRequest = null;
/**
 * Play loaded ads if google.ima.adsMangaer exists
 */
const playAds = (container, videoElement) => {
    if (!adsManager) {
        return;
    }
    const element = videoElement ?? container;
    adsManager.init(element.clientWidth, element.clientHeight, window.google?.ima?.ViewMode.NORMAL);
    adsManager.start();
};
/**
 * Listen to window resize event and resize adsManager
 */
export const WithImaSdk = (Base) => {
    return class AdapterWithImaSdk extends WithImaCallbacksDispatcher(Base) {
        hasIma = true;
        locale = '';
        waitImaSdkLoaded = null;
        container = null;
        videoElement = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        constructor(options) {
            super(options);
            this.resizeAds = this.resizeAds.bind(this);
            this.waitImaSdkLoaded = this.imaLoadSdk(options.locale);
        }
        async imaLoadSdk(locale = '') {
            try {
                if (!window.google?.ima) {
                    await injectScript({
                        src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
                    });
                }
            }
            catch (error) {
                this.dispatchAdBootError(error);
            }
            ima = window.google?.ima;
            this.locale = locale;
            if (!ima) {
                this.dispatchAdBootError(new Error('Could not find ima'));
            }
        }
        setView(view) {
            super.setView(view);
            (async () => {
                await this.waitImaSdkLoaded;
                let imaContainer = document.getElementById(IMA_CONTAINER_ID);
                if (imaContainer === null) {
                    imaContainer = document.createElement('div');
                    imaContainer.setAttribute('id', IMA_CONTAINER_ID);
                    imaContainer.style.position = 'absolute';
                    imaContainer.style.top = '0';
                    imaContainer.style.left = '0';
                    imaContainer.style.width = '100%';
                    this.getView()?.appendChild(imaContainer);
                }
                this.imaBoot({
                    container: imaContainer,
                    videoElement: document.getElementById(PLAYER_VIDEO_ID),
                    locale: this.locale,
                });
            })();
        }
        addResizeListener() {
            window.addEventListener('resize', this.resizeAds);
        }
        cleanupListenResize() {
            window.removeEventListener('resize', this.resizeAds);
        }
        resizeAds() {
            if (adsManager) {
                const element = this.videoElement ?? this.container;
                if (!element) {
                    return;
                }
                const width = element?.clientWidth;
                const height = element?.clientHeight;
                adsManager.resize(width, height, window.google?.ima?.ViewMode.NORMAL);
            }
        }
        imaBoot({ container, videoElement, locale, }) {
            this.container = container;
            this.videoElement = videoElement;
            if (!ima) {
                this.dispatchAdBootError(new Error('Could not find ima'));
                return;
            }
            ima.settings.setLocale(locale);
            if (!container) {
                this.dispatchAdBootError(new Error('Could not find ima container'));
                return;
            }
            if (config.supportSkippableAds) {
                ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
            }
            if (!videoElement) {
                adDisplayContainer = new ima.AdDisplayContainer(container);
            }
            else {
                adDisplayContainer = new ima.AdDisplayContainer(container, videoElement);
            }
            adsLoader = new ima.AdsLoader(adDisplayContainer);
            /** TIP: Must be done as the result of a user action */
            adDisplayContainer.initialize();
            const onAdsManagerLoaded = (event) => {
                if (!ima) {
                    this.dispatchAdBootError(new Error('Could not find ima'));
                    return;
                }
                const adsRenderingSettings = new ima.AdsRenderingSettings();
                adsRenderingSettings.bitrate = config.maxBitrate;
                adsRenderingSettings.useStyledLinearAds = false; // use mode with play/pause on player
                adsRenderingSettings.uiElements = []; // disable all UI elements (no, skip button will still be there)
                if (!videoElement) {
                    this.dispatchAdBootError(new Error('Could not find video element'));
                    return;
                }
                adsManager = event.getAdsManager(videoElement, adsRenderingSettings);
                adsManager.addEventListener(ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
                    /** google.ima.adsManager should be unmounted from video node */
                    this.cleanupAdsManager();
                    this.dispatchAdBreakEnded();
                });
                adsManager.addEventListener(ima.AdEvent.Type.COMPLETE, (e) => {
                    const adInfo = e.getAd();
                    this.dispatchAdEnded(adInfo);
                });
                adsManager.addEventListener(ima.AdEvent.Type.AD_METADATA, () => {
                    this.dispatchAdMetadata(adsManager?.getCuePoints() ?? []);
                });
                /**
                 * TIP: Could be fired pretty randomly before or after CONTENT_PAUSE_REQUESTED event
                 * so better rely on this event
                 */
                adsManager.addEventListener(ima.AdEvent.Type.LOADED, (e) => {
                    const adInfo = e.getAd();
                    const adPodInfo = adInfo?.getAdPodInfo();
                    this.dispatchAdPodInfo(adPodInfo);
                    this.dispatchAdLoaded(adInfo, adPodInfo);
                });
                adsManager.addEventListener(ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, () => {
                    this.addResizeListener();
                    this.dispatchContentPauseRequested();
                });
                adsManager.addEventListener(ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
                    this.cleanupListenResize();
                    this.dispatchContentResumeRequested();
                });
                adsManager.addEventListener(ima.AdEvent.Type.PAUSED, () => {
                    this.dispatchAdPaused();
                });
                adsManager.addEventListener(ima.AdEvent.Type.RESUMED, () => {
                    this.dispatchAdResumed();
                });
                adsManager.addEventListener(ima.AdEvent.Type.STARTED, (e) => {
                    const adInfo = e.getAd();
                    this.dispatchAdStarted({
                        ad_id: adInfo?.getAdId(),
                        ad_bitrate: config.maxBitrate,
                        ad_type: adInfo?.getContentType(),
                        ad_url: adInfo?.getMediaUrl() ?? undefined,
                        ad_width: adInfo?.getVastMediaWidth(),
                        ad_height: adInfo?.getVastMediaHeight(),
                    });
                });
                adsManager.addEventListener(ima.AdEvent.Type.AD_PROGRESS, (e) => {
                    const adData = e.getAdData();
                    this.dispatchAdPositionChanged(adData);
                });
                adsManager.addEventListener(ima.AdEvent.Type.CLICK, () => {
                    // pause advertisement when user opened advertiser url
                    adsManager?.pause();
                });
                adsManager.addEventListener(ima.AdEvent.Type.SKIPPED, () => {
                    this.dispatchAdSkipped();
                });
                try {
                    playAds(container, videoElement);
                }
                catch (adError) {
                    this.dispatchAdBootError(adError);
                }
            };
            const onAdsError = (event) => {
                const error = event.getError();
                this.cleanupAdsManager();
                this.dispatchAdError(error);
            };
            adsLoader.addEventListener(ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded);
            adsLoader.addEventListener(ima.AdErrorEvent.Type.AD_ERROR, onAdsError);
        }
        imaLoadAds(vastUrl) {
            /**
             * When Ad requested while another Ad is playing
             * we must destroy currently playing instance, IMA can't handle it
             */
            this.cleanupAdsManager();
            if (!ima) {
                this.dispatchAdBootError(new Error('Could not find ima'));
                return;
            }
            if (!adsLoader) {
                this.dispatchAdBootError(new Error('AdsLoader is not initialized'));
                return;
            }
            // Request video ads
            adsRequest = new ima.AdsRequest();
            // Add vast url to request
            adsRequest.adTagUrl = vastUrl;
            // Trigger onAdsManagerLoaded
            adsLoader.requestAds(adsRequest);
            this.dispatchAdBreakStarted();
        }
        imaSetVolume(volume) {
            adsManager?.setVolume(volume);
        }
        imaPause() {
            adsManager?.pause();
        }
        imaPlay() {
            adsManager?.resume();
        }
        imaStop() {
            this.cleanupAdsManager();
        }
        cleanupAdsManager() {
            this.cleanupListenResize();
            if (adsManager) {
                adsManager.destroy();
            }
            if (adsRequest) {
                adsRequest = null;
                adsLoader?.contentComplete();
            }
        }
    };
};
