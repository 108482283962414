import { SubtitlesCodec, } from '../capability';
export const watchParamsFromStreamType = (streamType) => ({
    stream_type: streamType,
    https_watch_urls: true,
});
export const watchParamsFromPlayOptions = (playOptions) => {
    if (playOptions?.pin) {
        return {
            youth_protection_pin: playOptions.pin,
        };
    }
    return {};
};
export const watchParamsFromCapability = (capability) => {
    if (capability?.subtitlesCodec) {
        return {
            vtt: SubtitlesCodec.VTT === capability.subtitlesCodec,
            ttml: SubtitlesCodec.TTML === capability.subtitlesCodec,
        };
    }
    return {};
};
/**
 * Picks highest rate from watch urls list
 * Remark: maxrate 0 means unlimited
 */
export const pickHighestRate = (watchUrls) => {
    if (watchUrls.length === 0) {
        return null;
    }
    const sort = (infoA, infoB) => {
        const maxRateA = infoA.maxrate || Number.POSITIVE_INFINITY;
        const maxRateB = infoB.maxrate || Number.POSITIVE_INFINITY;
        // order descending
        return maxRateB - maxRateA;
    };
    return watchUrls.slice().sort(sort)[0] ?? null;
};
