import { ImaEventKey } from '../enums';
export const WithImaEvent = (Base) => {
    return class extends Base {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        constructor(...args) {
            super(...args);
            if (this.isAdapterWithIma) {
                this.attachImaCallbacks();
            }
        }
        attachImaCallbacks() {
            const imaAdapter = this.adapter;
            imaAdapter.onAdBreakStarted = () => {
                this.triggerEvent(ImaEventKey.AD_BREAK_STARTED, {});
            };
            imaAdapter.onAdBreakEnded = () => {
                this.triggerEvent(ImaEventKey.AD_BREAK_ENDED, {});
            };
            imaAdapter.onAdLoaded = (data) => {
                this.triggerEvent(ImaEventKey.AD_LOADED, { data });
            };
            imaAdapter.onAdStarted = (data) => {
                this.triggerEvent(ImaEventKey.AD_STARTED, { data });
            };
            imaAdapter.onAdPaused = () => {
                this.triggerEvent(ImaEventKey.AD_PAUSED, {});
            };
            imaAdapter.onAdResumed = () => {
                this.triggerEvent(ImaEventKey.AD_RESUMED, {});
            };
            imaAdapter.onAdPositionChanged = (position) => {
                this.triggerEvent(ImaEventKey.AD_POSITION_CHANGED, { position });
            };
            imaAdapter.onAdEnded = (data) => {
                this.triggerEvent(ImaEventKey.AD_ENDED, { data });
            };
            imaAdapter.onAdSkipped = () => {
                this.triggerEvent(ImaEventKey.AD_SKIPPED, {});
            };
            imaAdapter.onAdError = (error) => {
                this.triggerEvent(ImaEventKey.AD_ERROR, { error });
            };
        }
    };
};
